const permissions = {
  dashboardTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  serviceTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  queueTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  userTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  branchTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  feedbackTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  settingTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  logTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  reportTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  workProfileTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  categoryTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  eventTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  formTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  livefeedTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  appointmentsDashboardTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  eservicesDashboardTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  instantTicketsLogTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  appointmentsLogTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  eservicesLogTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  donationDashboardTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  donationLogTab: {
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  smsCustomizeTab:{
    all: true,
    read: true,
    create: true,
    update: true,
    delete: true,
  }
};
const falsePermissions = {
  dashboardTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  serviceTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  queueTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  userTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  branchTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  feedbackTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  settingTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  logTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  reportTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  workProfileTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  categoryTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  eventTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  formTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  livefeedTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  appointmentsDashboardTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  eservicesDashboardTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  instantTicketsLogTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  appointmentsLogTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  eservicesLogTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  donationDashboardTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  donationLogTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  smsCustomizeTab: {
    all: false,
    read: false,
    create: false,
    update: false,
    delete: false,
  }
};

export let permission = [];
let dashboardTab = { dashboardTab: permissions.dashboardTab };
let serviceTab = { serviceTab: permissions.serviceTab };
let queueTab = { queueTab: permissions.queueTab };
let userTab = { userTab: permissions.userTab };
let branchTab = { branchTab: permissions.branchTab };
let feedbackTab = { feedbackTab: permissions.feedbackTab };
let settingTab = { settingTab: permissions.settingTab };
let logTab = { logTab: permissions.logTab };
let reportTab = { reportTab: permissions.reportTab };
let workProfileTab = { workProfileTab: permissions.workProfileTab };
let categoryTab = { categoryTab: permissions.categoryTab };
let eventTab = { eventTab: permissions.eventTab };
let formTab = { formTab: permissions.formTab };
let livefeedTab = { livefeedTab: permissions.livefeedTab };
let appointmentsDashboardTab = { appointmentsDashboardTab: permissions.appointmentsDashboardTab };
let eservicesDashboardTab = { eservicesDashboardTab: permissions.eservicesDashboardTab };
let instantTicketsLogTab = { instantTicketsLogTab: permissions.instantTicketsLogTab };
let appointmentsLogTab = { appointmentsLogTab: permissions.appointmentsLogTab };
let eservicesLogTab = { eservicesLogTab: permissions.eservicesLogTab };
let donationDashboardTab = { donationDashboardTab: permissions.donationDashboardTab };
let donationLogTab = { donationLogTab: permissions.donationLogTab };
let smsCustomizeTab = { smsCustomizeTab: permissions.smsCustomizeTab };
permission.push(
  dashboardTab,
  serviceTab,
  queueTab,
  userTab,
  branchTab,
  feedbackTab,
  settingTab,
  logTab,
  reportTab,
  workProfileTab,
  categoryTab,
  eventTab,
  formTab,
  livefeedTab,
  appointmentsDashboardTab,
  eservicesDashboardTab,
  instantTicketsLogTab,
  appointmentsLogTab,
  eservicesLogTab,
  donationDashboardTab,
  donationLogTab,
  smsCustomizeTab
);

export let falsePermission = [];
let dashboardTabF = { dashboardTab: falsePermissions.dashboardTab };
let serviceTabF = { serviceTab: falsePermissions.serviceTab };
let queueTabF = { queueTab: falsePermissions.queueTab };
let userTabF = { userTab: falsePermissions.userTab };
let branchTabF = { branchTab: falsePermissions.branchTab };
let feedbackTabF = { feedbackTab: falsePermissions.feedbackTab };
let settingTabF = { settingTab: falsePermissions.settingTab };
let logTabF = { logTab: falsePermissions.logTab };
let reportTabF = { reportTab: falsePermissions.reportTab };
let workProfileTabF = { workProfileTab: falsePermissions.workProfileTab };
let categoryTabF = { categoryTab: falsePermissions.categoryTab };
let eventTabF = { eventTab: falsePermissions.eventTab };
let formTabF = { eventTab: falsePermissions.formTab };
let livefeedTabF = { livefeedTab: falsePermissions.livefeedTab };
let appointmentsDashboardTabF = { appointmentsDashboardTab: falsePermissions.appointmentsDashboardTab };
let eservicesDashboardTabF = { eservicesDashboardTab: falsePermissions.eservicesDashboardTab };
let instantTicketsLogTabF = { instantTicketsLogTab: falsePermissions.instantTicketsLogTab };
let appointmentsLogTabF = { appointmentsLogTab: falsePermissions.appointmentsLogTab };
let eservicesLogTabF = { eservicesLogTab: falsePermissions.eservicesLogTab };
let donationDashboardTabF = { donationDashboardTab: falsePermissions.donationDashboardTab };
let donationLogTabF = { donationLogTab: falsePermissions.donationLogTab };
let smsCustomizeTabF = { smsCustomizeTab: falsePermissions.smsCustomizeTab };
falsePermission.push(
  dashboardTabF,
  serviceTabF,
  queueTabF,
  userTabF,
  branchTabF,
  feedbackTabF,
  settingTabF,
  logTabF,
  reportTabF,
  workProfileTabF,
  categoryTabF,
  eventTabF,
  formTabF,
  livefeedTabF,
  appointmentsDashboardTabF,
  eservicesDashboardTabF,
  instantTicketsLogTabF,
  appointmentsLogTabF,
  eservicesLogTabF,
  donationDashboardTabF,
  donationLogTabF,
  smsCustomizeTabF
);
